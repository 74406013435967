// 
// page-title.scss
//

.page-title-box {

    .page-title {
        font-size: 1.1rem;
        margin-bottom: 6px;
        color: $gray-900;
    }

    .breadcrumb {
        padding: 0;
    }
}

.page-title-box-alt { 
    background-color: transparent;
    padding: 20px 27px;
    box-shadow: none;
    margin-bottom: 0;
}

@media (max-width: 639px) {
    .page-title-box {
        display: block;

        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .page-title-right {
            display: block;
            padding-top: 5px;
        }
    }
}

.page-title-box-hori {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: -10px;
    padding: 5px 0;
}

@include media-breakpoint-down(md) {
    .page-title-box-hori {
        margin-top: -24px;
    }
}