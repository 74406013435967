

/* =============
   Rating
============= */

.rating-star{
  i{
    color: $text-muted;
  }
}

.rating-md {
  i {
    font-size: 16px;
  }
}

.rating-lg {
  i {
    font-size: 22px;
  }
}