// 
// gallery.scss
//

.filter-menu {
    margin-bottom: 20px;

    a {
        transition: all 0.3s ease-out;
        color: $dark;
        border-radius: 3px;
        padding: 5px 10px;
        display: inline-block;
        margin-bottom: 5px;
        font-family: $font-family-secondary;

        &:hover {
            background-color: $primary;
            color: $white;
        }
        
        &.active {
            background-color: $primary;
            color: $white;                                          
        }
    }
}

.port {
    margin-bottom: 30px;
  }

.portfolio-masonry-box {
    overflow: hidden;
    position: relative;
    padding: 0;
  
    .portfolio-masonry-img {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
  
      &:after {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($dark, 0);
        transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
        
      }
    }
  
    .portfolio-masonry-img > img {
      transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
      border-radius: 0;
    }
  
    .portfolio-masonry-detail {
      opacity: 0;
      width: 100%;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      text-align: center;
      transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
      z-index: 3;
  
      h4{
        margin-bottom: 3px;
        color: $light !important;
      }
      p{
        color: $light !important;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
  
  .portfolio-masonry-box:hover {
    .portfolio-masonry-detail {
        transform: translateY(-100%);
      opacity: 1;
    }
  
    .portfolio-masonry-img:after {
      background: rgba($dark,0.8);
    }
  
    .portfolio-masonry-img > img {
      transform: scale(1.05);
    }
  }
  
  