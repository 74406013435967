// 
// authentication.scss
//


// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid 
.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    background-position:  center;
    background-size: cover;

    .auth-fluid-form-box {
        max-width: 540px;
        border-radius: 0;
        z-index: 2;
        padding: 3rem 2rem;
        background-color: $auth-bg;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;

        @media (max-width: 575.98px) {
            position: relative;
        }
    }
}

.auth-brand {
    margin-bottom: 2rem;
}


@include media-breakpoint-down(md) {
    .auth-fluid {
        display: block;

        .auth-fluid-form-box {
            max-width: 100%;
            min-height: 100vh
        }
    
        .auth-fluid-right {
            display: none;
        }
    }
}

.auth-logo {
    .auth-logo-light {
        display: $logo-auth-light-display;
    }
    .auth-logo-dark {
        display: $logo-auth-dark-display;
    }
}
