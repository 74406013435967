//
// ion-rangeslider.scss
//

.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
}

.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
}

.irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}

.irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}

.irs-handle {
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;

    &.type_last {
        z-index: 2;
    }
}

.irs-min,
.irs-max {
    position: absolute;
    display: block;
    cursor: default;
}

.irs-min {
    left: 0;
}

.irs-max {
    right: 0;
}

.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
}

.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.irs-with-grid {
    .irs-grid {
        display: block;
    }
}

.irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: $secondary;

    &.small {
        height: 4px;
    }
}

.irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: $secondary;
}

.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}

.lt-ie9 .irs-disable-mask {
    background: $secondary;
    filter: alpha(opacity=0);
    cursor: not-allowed;
}

.irs-disabled {
    opacity: 0.4;
}

.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}


.irs--modern {
    .irs-line {
        border: none;
        background: $progress-bg;
    }

    .irs-bar {
        background: $primary;
        background: linear-gradient(to bottom, $primary 0%, darken($primary, 10%) 100%);
    }

    .irs-min,
    .irs-max {
        top: 0;
        padding: 1px 3px;
        color: $gray-700;
        font-size: 10px;
        line-height: 1.333;
        text-shadow: none;
        background-color: $progress-bg;
        border-radius: 4px;
    }

    .irs-handle > i:nth-child(1) {
        width: 8px;
        height: 8px;
    }

    .irs-from, .irs-to, .irs-single{
        background-color: $primary;
        &:before{
            border-top-color:  $primary;
        }
    }
}
