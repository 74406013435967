/* =================
   Spinners
==================== */

.sk-spinner{
  margin: 40px auto;
}

.sk-plane, .sk-chase-dot::before, .sk-bounce-dot, .sk-wave-rect, .sk-pulse,
.sk-flow-dot, .sk-swing-dot, .sk-circle-dot:before, .sk-grid-cube,
.sk-circle-fade-dot:before, .sk-fold-cube:before, .sk-wander-cube{
  background-color: $primary;
}
