// 
// taskboard.scss
//

.tasklist {
    min-height: 40px;
    margin-bottom: 0;

    li {
        background-color: lighten($card-bg, 2%);
        border: 1px solid $gray-300;
        padding: 20px;
        margin-bottom: 15px;
        border-radius: 3px;
        box-shadow: $components-shadow;


        &:last-of-type {
            margin-bottom: 0;
        }

        .btn-sm {
            padding: 2px 8px;
            font-size: 12px;
        }

    }

    .checkbox {
        margin-left: 20px;
        margin-top: 5px;
    }
}

.task-placeholder {
    border: 1px dashed rgba($light,0.9) !important;
    background-color: rgba($light,0.2) !important;
    padding: 20px;
}