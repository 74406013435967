// 
// error.scss
//

// Error text with shadow
.text-error {
    color: $primary;
    text-shadow: rgba($primary,0.3) 5px 1px, rgba($primary,0.2) 10px 3px;
    font-size: 84px;
    line-height: 90px;
    font-family: $font-family-secondary;
}
