//
// Daterange.scss
//

.daterangepicker {
    font-family: $font-family-base;
    background-color: $card-bg;
    border-color: $gray-300;

    
    .calendar-table{
        background-color: $card-bg;
        border-color: $gray-300;
        .next, .prev{
            span{
                border-color: $gray-500;
            }
        }
    }

    th, td{
        padding: 5px;
        &.week{
            color: $gray-400;
        }
        &.available{
            &:hover{
                background-color: $light;
            }
        }
    }

    td.active,
    td.active:hover,
    .ranges li.active {
        background-color: $primary;
    }

    .ranges {
        margin: 4px;

        li {
            border-radius: 2px;
            color: $dark;
            font-size: 12px;
            background-color: $light;
            border-color: $light;
            margin-bottom: 4px;

            &.active {
                background-color: $primary !important;
                color: $white !important;
            }

            &:hover {
                background-color: darken($light, 2%);
                color: $dark;
            }
          }
    }

    td{
        &.off, &.off.in-range, &.off.start-date,  &.off.end-date{
            background-color: transparent;
            color: rgba($gray-600, 0.7);
        }

        &.in-range{
            background-color: $light;
            color: $gray-600;
        }
    }

    .month, .calendar-time{
        select{
            background-color: $input-bg;
            border-color: $input-border-color;
            color: $input-color;

        }
    }

    &.show-ranges.ltr {
        .drp-calendar.left{
            border-left-color: $gray-300;
        }
    }

    
    .drp-buttons {
        border-top-color: $gray-300;
    }
}